@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --fontSize-xs: 12px;
  --fontSize-sm: 14px;
  --fontSize-base: 16px;
  --fontSize-lg: 18px;
  --fontSize-xl: 20px;
  --fontSize-2xl: 22px;
  --fontSize-3xl: 26px;
  --fontSize-4xl: 32px;
  --fontSize-5xl: 36px;
  --fontSize-6xl: 42px;
  --fontSize-7xl: 48px;
  --fontSize-8xl: 52px;
  --fontSize-8-5xl: 62px;
  --fontSize-9xl: 68px;
  --fontSize-10xl: 78px;

  --font-bai_jamjuree: 'Bai+Jamjuree', sans-serif;
  --font-proxima_nova: 'proxima-nova', sans-serif;

  --color-blue-200: #3471a3;
  --color-blue-300: #052c4c;

  --color-lime-300: #aef55c;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply overflow-x-hidden bg-black font-bai_jamjuree;
}

a {
  @apply text-blue-700;
}

.content p {
  @apply my-0;
}

.content ul {
  @apply my-6;
}

.featured-testimonial {
  @apply h-[465px] w-[730px] rounded-lg bg-[#AEF45C] pl-[51px];
  background-image: url('/assets/images/who-uses/featured-bg.webp');
}

.scrollbar::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
  @apply rounded-lg;
}

/* This styles the track (or progress bar) of the scrollbar */
.scrollbar::-webkit-scrollbar-track {
  background: #2b2e35;
  @apply h-2 rounded-lg;
}

/* This styles the handle (or thumb) of the scrollbar */
.scrollbar::-webkit-scrollbar-thumb {
  background: var(--color-lime-300);
  /* color of the scroll thumb */
  @apply rounded-lg;
}

/* This styles the handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--color-lime-300);
  /* color of the scroll thumb on hover */
}

path.rsm-geography {
  @apply fill-[#31333c] outline-0;
}

.rsm-marker {
  @apply cursor-pointer opacity-50 transition-all duration-300 hover:opacity-100;
}

.custom-video-tutorial {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.27);
  background: linear-gradient(123deg, #a8b2c0 1.13%, #616c7b 23.08%), #616c7b;
  width: 100%;
  padding: 24px 26px;
}

.tutorial-box {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.04));
}

.shape-box {
  @apply text-start text-base font-bold italic text-black;
  background-image: url('/assets/images/shape-box.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 220px;
  height: 35px;
}

.shape-box-sm {
  @apply text-start text-base font-bold italic text-black;
  background-image: url('/assets/images/shape-box-sm.webp');
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
  height: 30px;
}

.helpdesk-content {
  @apply font-medium leading-[200%] text-white;
}

.helpdesk-content h2 {
  @apply mb-10 text-3xl font-bold;
}

.helpdesk-content p:not(:last-child) {
  @apply mb-6;
}

@media (max-width: 426px) {
  body,
  .text-base {
    font-size: var(--fontSize-sm);
  }

  .text-lg {
    font-size: var(--fontSize-base);
  }

  .text-2xl {
    font-size: var(--fontSize-xl);
  }

  .text-3xl {
    font-size: var(--fontSize-xl);
  }

  .text-4xl {
    font-size: var(--fontSize-3xl);
  }

  .text-6xl {
    font-size: var(--fontSize-5xl);
  }

  .text-7xl {
    font-size: var(--fontSize-5xl);
  }

  .text-8xl {
    font-size: var(--fontSize-6xl);
  }

  .text-8-5xl {
    font-size: var(--fontSize-6xl);
  }

  .text-9xl {
    font-size: var(--fontSize-6xl);
  }

  .text-10xl {
    font-size: var(--fontSize-6xl);
  }
}

.dashboard-card-liner {
  background: linear-gradient(121deg, #49515d 2.28%, rgba(73, 81, 93, 0) 18.32%), #1d212a;
}

.stripe-card {
  border-style: solid;
  border-width: thin;
  padding: 1rem 2.5rem;
  border-color: #49545d;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.portals {
  position: relative;
  overflow: hidden;
}

.portals::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, black, transparent 30%, transparent 70%, black);
  pointer-events: none;
}

.search {
  background-color: #1e293b !important;
}

.react-tel-input {
  background: transparent !important;
  border: none !important;
  border-color: transparent !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: rgb(46, 58, 75) !important;
}

.country:hover,
.country:focus {
  background-color: #879bab38 !important;
  color: #ffffff !important;
}

.neon-border {
  box-shadow:
    0 0 1px rgba(174, 245, 92, 0.8),
    0 0 25px rgba(174, 245, 92, 0.8),
    0 0 10px rgba(174, 245, 92, 0.8);
  border-color: #aef55c;
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

.pulse-circle-white {
  animation: pulseWhite 1s infinite;
}

.pulse-circle-green {
  animation: pulseGreen 1s infinite;
  background: rgb(174, 245, 92);
}

@keyframes pulseGreen {
  0% {
    box-shadow: 0 0 0 0 rgb(174, 245, 92);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(88, 120, 243, 0);
  }

  100% {
    box-shadow: 0 0 0 50px rgba(88, 120, 243, 0);
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: 0 0 0 0 rgb(225, 229, 243);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(88, 120, 243, 0);
  }

  100% {
    box-shadow: 0 0 0 50px rgba(88, 120, 243, 0);
  }
}
/* -- */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-enter {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeInRightToLeft {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-enter-img {
  animation: fadeInRightToLeft 0.5s forwards;
}

.buttonAcceptEssential {
  color: #000 !important;
}
#cookie-consent p {
  color: #fff !important;
}

iframe:focus-visible {
  outline: none;
}

.swiper-scrollbar {
  background-color: #2b2e35 !important;
  height: 0.5rem !important;
}

.swiper-scrollbar-drag {
  background-color: #aef55c !important;
}
